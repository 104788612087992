import { useState, useCallback } from 'react'

const SWIPE_THRESHOLD = 50 // Adjust as needed

const useSwipe = (onSwipeLeft, onSwipeRight) => {
  const [startTouch, setStartTouch] = useState(null)

  const handleTouchStart = useCallback((e) => {
    const touch = e.touches[0]
    setStartTouch({
      x: touch.clientX,
      y: touch.clientY,
    })
  }, [])

  const handleTouchEnd = useCallback(
    (e) => {
      if (!startTouch) return

      const touch = e.changedTouches[0]
      const deltaX = touch.clientX - startTouch.x

      if (Math.abs(deltaX) > SWIPE_THRESHOLD) {
        if (deltaX > 0) {
          onSwipeRight()
        } else {
          onSwipeLeft()
        }
      }

      setStartTouch(null)
    },
    [startTouch, onSwipeLeft, onSwipeRight]
  )

  return {
    onTouchStart: handleTouchStart,
    onTouchEnd: handleTouchEnd,
  }
}

export default useSwipe
