import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import BrandInfo from 'components/BrandInfo'
import { DangerLabel } from 'components/Typography'
import Categories from 'components/Categories'
import Hero from 'components/Hero'
import Layout from 'components/Layout'
import Modal from 'components/Modal'
import OpenHours from 'components/OpenHours'
import OpeningTimes from 'components/OpeningTimes'
import { Container, Spacing50 } from 'styles/sharedStyle'
import openingHoursUtils from 'utils/openingHours'
import theme from 'styles/theme'
import StacklaWidget from 'components/StacklaWidget'
import renderContent from 'utils/componentRenderer'
import trackGTM from 'utils/trackGTM'
import GA4 from 'utils/GA4'
import LatestOffers from 'components/LatestOffers/LatestOffers'
import constants from '../constants'

const BrandDetailsPage = (props) => {
  const brandOpenTimesModalRef = useRef()
  const {
    data: {
      page,
      page: {
        jsonLdSchema,
        openStatus,
        brandLogo,
        closingDownCopy,
        openingHours: brandOpeningHours,
        hero,
        categories,
        village,
        stacklaCarousel,
        village: { openingHours: villageOpeningHours },
        content,
        hideCategories,
        virtualBoutiqueOnly,
      },
      openingHoursLabels,
      openingHoursExceptions,
      brandOpeningHoursExceptions,
      latestOffers: { nodes: latestOffers },
      settings: { nodes: settings },
    },
    location: { pathname },
    pageContext: { pageLevel, nodeLocale },
  } = props

  useEffect(() => {
    if (pathname.includes('brands')) {
      trackGTM('brands', 'brands', 'brand search', pathname)
    }
    GA4('viewed_brand', { brand_name: page.name, village_name: village?.name })
  }, [pathname])

  const isBrandOpen =
    openStatus === constants.OPEN_STATUS ||
    openStatus === constants.CLOSING_STATUS

  const shouldDisplayRedClosingCopy =
    openStatus === constants.CLOSING_STATUS ||
    openStatus === constants.CLOSED_STATUS ||
    openStatus === constants.REOPENING_STATUS

  const isBrandStatusFlux =
    [
      constants.OPENING_STATUS,
      constants.OPEN_STATUS,
      constants.CLOSING_STATUS,
      constants.CLOSED_STATUS,
      constants.REOPENING_STATUS,
    ].indexOf(openStatus) > -1

  const labels = (village && village.labels && village.labels[0]) || {}
  const villagePlaceholderImage =
    village && village.villagePlaceholderImage
      ? village.villagePlaceholderImage
      : null

  const globalLabels = openingHoursLabels.edges[0].node
  const villageExceptions = openingHoursExceptions.exceptions.reduce(
    openingHoursUtils.reduceExceptions,
    {}
  )
  const brandExceptions = brandOpeningHoursExceptions.exceptions.reduce(
    openingHoursUtils.reduceExceptions,
    {}
  )

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)

  let heroImage = {}

  if (hasHeroImage) {
    heroImage = {
      portrait: media.portrait || media.videoPlaceholder.portrait,
      landscape: media.landscape || media.videoPlaceholder.landscape,
      altText:
        media.altText ||
        (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
    }
  } else if (villagePlaceholderImage) {
    heroImage = {
      portrait: villagePlaceholderImage.portrait,
      landscape: villagePlaceholderImage.landscape,
      altText: villagePlaceholderImage.altText || '',
    }
  }

  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        content: !!isBrandStatusFlux && closingDownCopy && (
          <span data-as="Title32">{closingDownCopy}</span>
        ),
        image:
          openStatus === constants.CLOSED_STATUS ||
          openStatus === constants.REOPENING_STATUS
            ? {}
            : heroImage,
        logo: brandLogo,
        video: heroVideo,
        isCondensed: hero.isCondensed,
        opacity: hero.opacity,
        audiences: hero.audiences,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const brandHoursLabel = openingHoursUtils.getOpeningHours(
    villageOpeningHours,
    globalLabels,
    {
      brandOpeningHours,
      brandExceptions,
      villageExceptions,
    }
  )

  const brandHours = openingHoursUtils.getWeeklyOpeningHours(
    villageOpeningHours,
    globalLabels,
    {
      numberOfWeeks: 1,
      brandOpeningHours,
      brandExceptions,
      villageExceptions,
    }
  )

  const footNoteRequired =
    brandHours && brandHours.length
      ? !!brandHours[0].find((hours) => hours.day.indexOf('*') !== -1)
      : false

  const brandOpenHoursProps = {
    virtualBoutiqueOnly,
    virtualBoutiqueOnlyText: labels.virtualBoutiqueOnlyText,
    statusLabel: globalLabels.brandRibbonLabel,
    hoursLabel: brandHoursLabel,
    // @ts-ignore
    onClick: () => brandOpenTimesModalRef.current.openModal(),
  }

  const hasOffersToDisplay = latestOffers?.length > 0

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>

      {heroProps && <Hero {...heroProps} />}

      {isBrandOpen && (
        <>
          <OpenHours {...brandOpenHoursProps} />
          <Modal ref={brandOpenTimesModalRef}>
            {brandHours.map((hours, index) => (
              <OpeningTimes
                key={`week_${index.toString()}`}
                conditionsCopy={
                  (brandOpeningHours &&
                    brandOpeningHours.footNoteCopy &&
                    brandOpeningHours.footNoteCopy.footNoteCopy) ||
                  (footNoteRequired &&
                    villageOpeningHours &&
                    villageOpeningHours.footNoteCopy &&
                    villageOpeningHours.footNoteCopy.footNoteCopy)
                }
                times={hours}
                title={globalLabels.brandHeading}
                isModal
              />
            ))}
          </Modal>
        </>
      )}

      <Spacing50 />

      {shouldDisplayRedClosingCopy && (
        <Container p={`${theme.space[11]} 0 0`} textAlign="center">
          <DangerLabel>{closingDownCopy}</DangerLabel>
        </Container>
      )}

      {isBrandOpen && (
        <BrandInfo
          brandCopy={page.brandCopy}
          brandName={`${page.name} - ${village?.name}`}
          readMoreCopy={
            page.brandReadMoreCopy &&
            page.brandReadMoreCopy.childMarkdownRemark.html
          }
          readLessLabel={labels.readLessLabel}
          readMoreLabel={labels.readMoreLabel}
        />
      )}

      {hasOffersToDisplay && (
        <LatestOffers
          latestOffers={latestOffers}
          hero={stacklaCarousel}
          settings={settings}
          nodeLocale={nodeLocale}
          village={village}
        />
      )}

      {isBrandOpen && !hideCategories && categories && (
        <Categories items={categories} />
      )}

      {renderContent(content, props)}

      {!!stacklaCarousel && !!stacklaCarousel.code && (
        <StacklaWidget
          code={stacklaCarousel.code.code}
          eyebrow={stacklaCarousel.eyebrow}
          headline={stacklaCarousel.headline}
          ctaLabel={stacklaCarousel.ctaLabel}
          ctaUrl={stacklaCarousel.ctaUrl}
        />
      )}

      <Spacing50 />
    </Layout>
  )
}

BrandDetailsPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.shape({
    nodeLocale: PropTypes.string.isRequired,
    pageLevel: PropTypes.string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
}

export const brandDetailsPageQuery = graphql`
  query(
    $id: String!
    $name: String
    $openingHoursId: String!
    $brandOpeningHoursId: String!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $villageSlug: String
    $pageTypeSlug: String
    $needContactIcons: Boolean = true
    $dateNow: Date!
    $fullDateNow: Date!
  ) {
    page: contentfulPageTemplateBrandDetailT06(id: { eq: $id }) {
      id
      jsonLdSchema {
        jsonLdSchema
      }
      name
      brandLink
      brandCopy
      brandReadMoreCopy {
        childMarkdownRemark {
          html
        }
      }
      hideCategories
      openStatus: flags
      virtualBoutiqueOnly
      closingDownCopy
      categories {
        name
        label
      }
      brandLogo: logoImage {
        code {
          code
        }
      }
      hero {
        isCondensed
        opacity
        ...heroMediaQuery
        audiences: childrenContentfulCompHeroHer01AudiencesJsonNode {
          content
        }
      }
      village {
        name
        villageSlug: slug
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
          locale: node_locale
        }
        openingHours {
          ...villageOpeningHours
        }
        openingStatusLabel
        villagePlaceholderImage: image {
          __typename
          ... on Node {
            ... on ContentfulEntityImageEnt02 {
              ...ResponsiveImageQuery
            }
          }
        }
        labels: label_brand_details_lab01 {
          readMoreLabel
          readLessLabel
          contactDetailsHeader
          ...virtualShoppingIconsAndLabelsQuery
          onlineLabel
          viewOnMapLabel
          virtualBoutiqueOnlyText
        }
        defaultLocale
      }
      content {
        __typename
        ... on Node {
          ...multipleComponentsT06
        }
      }
      openingHours {
        ...brandOpeningHours
      }
      hideFromSearchEngine
      pageTitle
      pageDescription
      stacklaCarousel {
        code {
          code
        }
        eyebrow
        headline
        ctaLabel
        ctaUrl
      }
    }
    brandOpeningHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_boutique_oph02: {
          elemMatch: { id: { eq: $brandOpeningHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          date(formatString: "DD-MM-YYYY")
          openingTime
          closingTime
          closedFlag
          label
        }
      }
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    mapTranslations: allContentfulEntityPageTypeEnt06(
      filter: { instanceName: { eq: "map" }, node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          nodeLocale: node_locale
          slug
        }
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
    latestOffers: allContentfulCompOfferCard01(
      sort: { fields: [brandLink___name], order: [ASC] }
      filter: {
        node_locale: { eq: $nodeLocale }
        brandLink: { name: { eq: $name } }
        onBrandPage: { eq: true }
        publishedDate: { lte: $fullDateNow }
        offerRunFinishDate: { gte: $dateNow }
        page_template_offers_campaign_t18: {
          elemMatch: { village: { slug: { eq: $villageSlug } } }
        }
      }
      limit: 2
    ) {
      nodes {
        offerTitle
        publishedDate
        offerRunStartDate
        offerRunFinishDate
        offerSubtitle
        onBrandPage
        description {
          childMarkdownRemark {
            html
          }
        }
        termsAndConditions {
          childMarkdownRemark {
            html
          }
        }
        membersOnly
        signInSignUp {
          signinLabel
          signinUrl
          signupLabel
          signupUrl
        }
      }
    }
    settings: allContentfulEntityCampaignTemplateSettings(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        id
        floatIslandBG
        offerCardColour2
        offerCardPrimaryBG
        offerGridArrowColour
        offerGridMasterHL
        offerGridListViewBG
        updatedAt
        visitCTA
        brandPageTitle
        visitURL
        visitTitle {
          childMarkdownRemark {
            html
          }
        }
        membersOnlyLabelText
      }
    }
  }
`

export default BrandDetailsPage
