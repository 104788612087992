import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MarkdownRenderer from 'components/MarkdownRenderer'
import { Title32, Title60 } from 'components/Typography'
import theme from 'styles/theme'

import { Spacing50, Spacing30 } from 'styles/sharedStyle'
import {
  Icon,
  MoreButton,
  MoreIcon,
  MoreWrapper,
  Wrapper,
} from './BrandInfo.style'

function BrandInfo({
  brandName,
  brandCopy,
  readLessLabel,
  readMoreCopy,
  readMoreLabel,
}) {
  const [isOpen, setIsOpen] = useState(false)

  const handleReadMoreToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Wrapper>
      <Spacing50 />
      <Title60 as="h1">{brandName}</Title60>
      <Spacing50 />
      <Title32 as="h2">{brandCopy}</Title32>

      {readMoreCopy && (
        <MoreWrapper visible={isOpen}>
          <MarkdownRenderer mt={theme.space[10]} html={readMoreCopy} />
        </MoreWrapper>
      )}

      {readMoreCopy && (
        <>
          <Spacing30 />
          <MoreButton type="button" onClick={handleReadMoreToggle}>
            {isOpen ? readLessLabel : readMoreLabel}
            <Icon isOpen={isOpen}>
              <MoreIcon
                width={[8]}
                height={[8]}
                transform={isOpen ? `rotate(90deg)` : `rotate(-90deg)`}
              />
            </Icon>
          </MoreButton>
        </>
      )}
      <Spacing50 />
    </Wrapper>
  )
}

BrandInfo.propTypes = {
  brandCopy: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  readLessLabel: PropTypes.string,
  readMoreCopy: PropTypes.string,
  readMoreLabel: PropTypes.string,
}

BrandInfo.defaultProps = {
  readLessLabel: null,
  readMoreCopy: null,
  readMoreLabel: null,
}

export default BrandInfo
