import React from 'react'
import PropTypes from 'prop-types'

import { CtaLabel } from 'components/Typography'
import { BoxedItem, Spacing50 } from 'styles/sharedStyle'
import theme from 'styles/theme'

import { CategoriesWrapper } from './Categories.style'

const Categories = ({ items }) => (
  <CategoriesWrapper>
    {items.map(({ label }, index) => (
      <BoxedItem key={`${label}_${index.toString()}`}>
        <CtaLabel display="block" p={`${theme.space[2]} ${theme.space[4]}`}>
          {label}
        </CtaLabel>
      </BoxedItem>
    ))}
    <Spacing50 />
  </CategoriesWrapper>
)

Categories.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Categories
