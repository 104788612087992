// Date utils
/**
 * Formats a date into DD/MM/YYYY HH:MM.
 * @returns {string} The formatted date string.
 */
export default function formatDate() {
  const d = new Date()
  const year = d.getFullYear()
  const month = `0${d.getMonth() + 1}`.slice(-2)
  const day = `0${d.getDate()}`.slice(-2)
  const hours = `0${d.getHours()}`.slice(-2)
  const minutes = `0${d.getMinutes()}`.slice(-2)
  return `${day}/${month}/${year} ${hours}:${minutes}`
}

export function getMonthName(date = null, locale = 'default') {
  return new Date(date).toLocaleString(locale, { month: 'long' })
}

export function formatHeroDate(date, locale, complexDate = false) {
  const dateString = complexDate ? date : date.replace(/\+(\w)+/, '')
  const dt = new Date(dateString)
  return `${dt.getDate()} ${getMonthName(dt, locale)}`
}

export function formatYear(date, complexDate = false) {
  const dateString = complexDate ? date : date.replace(/\+(\w)+/, '')
  const dt = new Date(dateString)
  return `${dt.getFullYear()}`
}
