import React, { useState } from 'react'
import useSwipe from 'hooks/useSwipe'
import { normalizeInternalUrl } from 'utils/urlUtils'
import { Spacing30, Spacing60 } from 'styles/sharedStyle'
import PropTypes from 'prop-types'
import { formatHeroDate } from 'utils/date'
import { useComponentId, useFieldId } from 'utils/optimizely'
import { navigate } from 'gatsby'
import {
  Button,
  ButtonWrapper,
  CustomSpacing,
  CustomSpacingFooter,
  LatestOfferLine1,
  LatestOfferLine1Number,
  LatestOfferLine2,
  LatestOfferLine2Number,
  LatestOfferLinesWrapper,
  LatestOffersCardDescription,
  LatestOffersCardLegalTerms,
  LatestOffersCardSubTitle,
  LatestOffersCardTitle,
  LatestOffersDate,
  LatestOffersFrame,
  LatestOffersTitle,
  LatestOffersWrapper,
  LineContainer,
  PhantonButton,
  SpacingDescription,
  SpacingHeader,
  SpacingTitle,
  TitlesFrame,
} from './LatestOffer.style'

const LatestOffers = ({
  latestOffers,
  settings,
  inverted,
  nodeLocale,
  village,
}) => {
  const [offerIndex, setIndexOffer] = useState(0)
  const [colorLine1, setColorLine1] = useState('rgba(0, 0, 0, 0.5)')
  const [colorLine2, setColorLine2] = useState('rgba(0, 0, 0, 0.2)')
  const [fade, setFade] = useState(true)

  const campaignDateContent = `${formatHeroDate(
    latestOffers[offerIndex].offerRunStartDate,
    nodeLocale ?? 'en'
  )} - ${formatHeroDate(
    latestOffers[offerIndex].offerRunFinishDate,
    nodeLocale ?? 'en'
  )} ${new Date(latestOffers[offerIndex].offerRunFinishDate).getFullYear()}`

  const latestOffersProps = {
    campaignDateContent,
    title: latestOffers[offerIndex]?.offerTitle,
    subTitle: latestOffers[offerIndex]?.offerSubtitle,
    description:
      latestOffers[offerIndex]?.description?.childMarkdownRemark?.html,
    termsAndConditions: latestOffers[offerIndex]?.termsAndConditions,
    signinLabel: latestOffers[offerIndex]?.signInSignUp?.signinLabel,
    signinUrl: latestOffers[offerIndex]?.signInSignUp?.signinUrl,
    signupUrl: latestOffers[offerIndex]?.signInSignUp?.signupUrl,
    signupLabel: latestOffers[offerIndex]?.signInSignUp?.signupLabel,
  }

  const elementId = useComponentId(`Comp_Offer_Card_Detail`)
  const showSigninGroup =
    latestOffers[offerIndex].membersOnly &&
    latestOffers[offerIndex].signInSignUp
  const onCTAClick = (to) => navigate(to)
  const planYourVisitCTA = settings[0]?.visitCTA
  const planYourVisitLINK = normalizeInternalUrl(settings[0]?.visitURL ?? '')

  const backgroundColor = inverted ? 'fff' : settings[0].offerCardPrimaryBG
  const invertedColor = inverted ? settings[0].offerCardPrimaryBG : 'fff'

  const ChangeLatestOfferIndex = (index) => {
    setFade(false)
    setTimeout(() => {
      switch (index) {
        case 0:
          setColorLine1('rgba(0, 0, 0, 0.5)')
          setColorLine2('rgba(0, 0, 0, 0.2)')
          break

        case 1:
          setColorLine1('rgba(0, 0, 0, 0.2)')
          setColorLine2('rgba(0, 0, 0, 0.5)')
          break

        default:
          return
      }
      setIndexOffer(index)
      setFade(true)
    }, 500)
  }

  const handleSwipeLeft = () => {
    if (offerIndex < latestOffers.length - 1) {
      ChangeLatestOfferIndex(offerIndex + 1)
    }
  }

  const handleSwipeRight = () => {
    if (offerIndex > 0) {
      ChangeLatestOfferIndex(offerIndex - 1)
    }
  }

  const swipeHandlers = useSwipe(handleSwipeLeft, handleSwipeRight)

  return (
    <LatestOffersWrapper>
      <LatestOffersTitle>{settings[0].brandPageTitle}</LatestOffersTitle>
      <CustomSpacing />
      <LatestOffersFrame {...swipeHandlers} style={{ opacity: fade ? 1 : 0 }}>
        <SpacingHeader />
        <LatestOffersDate>
          {latestOffersProps.campaignDateContent}
        </LatestOffersDate>
        <SpacingTitle />
        <TitlesFrame>
          <LatestOffersCardTitle>
            {latestOffersProps.title}
          </LatestOffersCardTitle>
          <LatestOffersCardSubTitle>
            {latestOffersProps.subTitle}
          </LatestOffersCardSubTitle>
        </TitlesFrame>
        <SpacingDescription />
        {latestOffersProps?.description && (
          <LatestOffersCardDescription
            dangerouslySetInnerHTML={{ __html: latestOffersProps.description }}
          />
        )}
        {showSigninGroup && (
          <ButtonWrapper>
            {latestOffersProps.signupLabel && (
              <Button
                color={invertedColor}
                backgroundColor={backgroundColor}
                id={useFieldId(elementId, `register-cta`)}
                onClick={() => onCTAClick(latestOffersProps.signupUrl)}>
                {latestOffersProps.signupLabel}
              </Button>
            )}
            <PhantonButton
              color={invertedColor}
              id={useFieldId(elementId, `singin-cta`)}
              level="quaternary"
              onClick={() =>
                onCTAClick(
                  `/${village?.villageSlug}/${nodeLocale}${planYourVisitLINK}`
                )
              }>
              {planYourVisitCTA}
            </PhantonButton>
          </ButtonWrapper>
        )}
        <CustomSpacingFooter />
        {latestOffersProps?.termsAndConditions && (
          <LatestOffersCardLegalTerms
            html={latestOffersProps.termsAndConditions}
          />
        )}
      </LatestOffersFrame>
      <Spacing60 />
      {latestOffers.length > 1 && (
        <LatestOfferLinesWrapper>
          <LineContainer>
            <LatestOfferLine1 colorLine1={colorLine1} />
            <LatestOfferLine1Number
              colorLine1={colorLine1}
              onClick={() => ChangeLatestOfferIndex(0)}>
              1
            </LatestOfferLine1Number>
          </LineContainer>
          <LineContainer>
            <LatestOfferLine2 colorLine2={colorLine2} />
            <LatestOfferLine2Number
              colorLine2={colorLine2}
              onClick={() => ChangeLatestOfferIndex(1)}>
              2
            </LatestOfferLine2Number>
          </LineContainer>
        </LatestOfferLinesWrapper>
      )}
      <Spacing30 />
    </LatestOffersWrapper>
  )
}

LatestOffers.propTypes = {
  latestOffers: PropTypes.arrayOf(
    PropTypes.shape({
      offerTitle: PropTypes.string,
      offerSubtitle: PropTypes.string,
      description: PropTypes.string,
      termsAndConditions: PropTypes.string,
      signinLabel: PropTypes.string,
      signinUrl: PropTypes.string,
      signupUrl: PropTypes.string,
      signuplabel: PropTypes.string,
      membersOnly: PropTypes.bool,
      offerRunStartDate: PropTypes.string,
      offerRunFinishDate: PropTypes.string,
      signInSignUp: PropTypes.bool,
    })
  ),
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      visitCTA: PropTypes.string,
      visitURL: PropTypes.string,
      offerCardPrimaryBG: PropTypes.string,
      brandPageTitle: PropTypes.string,
    })
  ),
  hero: PropTypes.shape({
    cta1Link: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
  }),
  inverted: PropTypes.bool,
  nodeLocale: PropTypes.string,
  village: PropTypes.shape({
    villageSlug: PropTypes.string,
  }),
}

LatestOffers.defaultProps = {
  latestOffers: [
    {
      OfferTitle: '',
      OfferSubtitle: '',
      description: '',
      termsAndConditions: '',
      signinUrl: '',
      signupUrl: '',
      signinLabel: '',
      signupLabel: '',
      membersOnly: false,
      offerRunStartDate: '',
      offerRunFinishDate: '',
    },
  ],
  settings: [
    {
      visitCTA: '',
      visitURL: '',
      offerCardPrimaryBG: '',
      brandPagetitle: '',
    },
  ],
  hero: {
    cta1Link: '',
    ctaLabel: '',
    ctaUrl: '',
  },
  inverted: false,
  nodeLocale: 'en',
  village: {
    villageSlug: 'bicester-village',
  },
}

export default LatestOffers
