/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

import theme from 'styles/theme'

export const CategoriesWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${theme.space[3]};
  max-width: ${theme.containterMaxWidth};
  text-align: center;
  list-style: none;

  ${theme.mediaQueries.small} {
    display: block;
    position: relative;
  }
`
